import React, { useState } from 'react';
import Header from './components/Header.js';
import Footer from './components/Footer.js';
import CardBlock from "./components/CardBlock.js";
import MainScreen from "./components/MainScreen.js";
import TechnologiesCarousel from './components/TechnologiesCarousel';
import ThreeButtonsModule from './components/ThreeButtonsModule.js';
import Padding from './components/Padding.js';
import ContactsModule from './components/ContactsModule.js';
import './App.css';
import Popup from './components/Popup.js'; // Импорт Popup
import './i18n.js'; // Импорт конфигурации
import { useTranslation } from 'react-i18next';

const App = () => {
  
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupContent, setPopupContent] = useState(null);

  const openPopup = (content) => {
    setPopupContent(content);
    setIsPopupOpen(true);

    
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setPopupContent(null);
  };

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Смена языка
  
  }
  return (
    <div className="app">
      <Header />
      <main className="main-content">
      <MainScreen onCardClick={() => openPopup(<ContactsModule />)}/>
      <Padding top={5} bottom={0}/>
      <ThreeButtonsModule/>
      <Padding top={5} bottom={0}/>
      <CardBlock onCardClick={() => openPopup(<ContactsModule />)} />
      <Padding top={5} bottom={0}/>
      <TechnologiesCarousel />;
      <ContactsModule/>
      </main>
      <Footer />
      {isPopupOpen && (
  <Popup isOpen={isPopupOpen} onClose={closePopup}>
    {popupContent}
  </Popup>
)}

    </div>
  );
};

export default App;
